import React from 'react'
import {Link} from 'gatsby'

const Footer = () => {
  return (
    <footer className="footer bg-gray-200 relative pt-1 border-b-2 border-gray-700">
      <div className="container mx-auto px-6 text-center mt-12">
        <div className="block text-center">
          <div className="flex flex-wrap items-baseline justify-center">
            <Link to='/' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
              Home
            </Link>
            <Link to='/about' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
              About
            </Link>
            <Link to='/projects' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
              Projects
            </Link>
            <Link to='/blog' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
              Blog
            </Link>
            <Link to='/contact' className="navigation text-gray-600 hover:text-blue-500 dark:hover:text-white px-3 py-2 rounded-md font-medium">
              Contact
            </Link>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-6 text-center mt-6">
        <ul className="space-x-4">
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://github.com/rijadhusic" rel="noopener" aria-label="GitHub" target="_blank">
              <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M255.968 5.329C114.624 5.329 0 120.401 0 262.353c0 113.536 73.344 209.856 175.104 243.872 12.8 2.368 17.472-5.568 17.472-12.384 0-6.112-.224-22.272-.352-43.712-71.2 15.52-86.24-34.464-86.24-34.464-11.616-29.696-28.416-37.6-28.416-37.6-23.264-15.936 1.728-15.616 1.728-15.616 25.696 1.824 39.2 26.496 39.2 26.496 22.848 39.264 59.936 27.936 74.528 21.344 2.304-16.608 8.928-27.936 16.256-34.368-56.832-6.496-116.608-28.544-116.608-127.008 0-28.064 9.984-51.008 26.368-68.992-2.656-6.496-11.424-32.64 2.496-68 0 0 21.504-6.912 70.4 26.336 20.416-5.696 42.304-8.544 64.096-8.64 21.728.128 43.648 2.944 64.096 8.672 48.864-33.248 70.336-26.336 70.336-26.336 13.952 35.392 5.184 61.504 2.56 68 16.416 17.984 26.304 40.928 26.304 68.992 0 98.72-59.84 120.448-116.864 126.816 9.184 7.936 17.376 23.616 17.376 47.584 0 34.368-.32 62.08-.32 70.496 0 6.88 4.608 14.88 17.6 12.352C438.72 472.145 512 375.857 512 262.353 512 120.401 397.376 5.329 255.968 5.329z"/></svg>
            </a>
          </li>
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://www.linkedin.com/in/rijadhusic/" rel="noopener" aria-label="LinkedIn" target="_blank">
            <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 160h114.496v352H0zM426.368 164.128c-1.216-.384-2.368-.8-3.648-1.152a74.218 74.218 0 00-4.64-.896c-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z"/><circle cx="56" cy="56" r="56"/></svg>
            </a>
          </li>
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://stackoverflow.com/users/5623067/rijad-husic" rel="noopener" aria-label="Stack Overflow" target="_blank">
            <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.444 14.839l10.338 2.196.433-2.089-10.338-2.212zM6.215 17.571h10.566v2.127H6.215zM7.8 9.831l9.572 4.526.887-1.944-9.577-4.538zM17.373 14.358l-.001-.001-.001.001z"/><path d="M2 15.429V24h18.992v-8.571h-2.113v6.428H4.113v-6.428zM10.453 5.063l8.109 6.873 1.346-1.65-8.109-6.873zM22 8.587L15.698 0l-1.691 1.286 6.302 8.587z"/></svg>
            </a>
          </li>
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://www.facebook.com/rijadhusic93/" rel="noopener" aria-label="Facebook" target="_blank">
              <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h192V336h-64v-80h64v-64c0-53.024 42.976-96 96-96h64v80h-32c-17.664 0-32-1.664-32 16v64h80l-32 80h-48v176h96c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z"/></svg>
            </a>
          </li>
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://www.instagram.com/rijadhusic93/" rel="noopener" aria-label="Instagram" target="_blank">
              <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z"/><path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"/><circle cx="393.6" cy="118.4" r="17.056"/></svg>
            </a>
          </li>
          <li className="inline-block">
            <a className="flex text-gray-700" href="https://twitter.com/rijadhusic93" rel="noopener" aria-label="Twitter" target="_blank">
              <svg className="text-gray-500 hover:text-blue-500 fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"/></svg>
            </a>
          </li>
        </ul>
        <div className="border-b border-gray-400 mt-1 mx-auto w-32"></div>
      </div>
      <div className="container mx-auto px-6">
          <div className="border-gray-300 flex flex-col items-center">
              <div className="sm:w-2/3 text-center py-6">
                  <p className="mb-1 text-gray-600">
                  Build with <a className="text-gray-700 hover:text-blue-600" rel="noopener" aria-label="Gatsby" target="_blank" href="https://www.gatsbyjs.com/">Gatsby</a>, <a className="text-gray-700 hover:text-blue-600" rel="noopener" aria-label="Sanity" target="_blank" href="https://www.sanity.io/">Sanity</a> and <a className="text-gray-700 hover:text-blue-600" rel="noopener" aria-label="TailwindCss" target="_blank" href="https://tailwindcss.com/">TailwindCss</a>
                  </p>
                  <p className="mb-1 text-gray-600">
                  &copy; {new Date().getFullYear()} Rijad Husic
                  </p>
              </div>
          </div>
      </div>
    </footer>
  )
};
export default Footer
