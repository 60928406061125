import React from 'react'

const ErrorMsg = ({children}) => {
  return (
    <div className="bg-yellow-100 border-l-4 border-yellow-400 mt-20 p-4 text-orange-dark" role="alert">
        {children}
    </div>
  );
}

export default ErrorMsg
