import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import MyForm from '../components/myform'
import Layout from '../containers/layout'
import Footer from '../components/footer';

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`


const ContactPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        <div className="z-10 items-center overflow-hidden flex-grow">
          <div className="container mx-auto px-6 py-8 lg:py-20 text-center">

            <h1 className="font-bebas-neue uppercase text-4xl sm:text-6xl font-black leading-none dark:text-white text-gray-800">
              Contact Me
            </h1>
            <h2 className="font-bebas-neue text-lg sm:text-xl font-black leading-none dark:text-white text-gray-800">Have questions? I have answers.</h2>
          </div>
          <div className="container mx-auto px-4 w-full lg:w-2/3 pb-8 text-center">
            <p className="font-bebas-neue text-lg sm:text-xl font-black leading-none dark:text-white text-gray-800">
            Want to get in touch? Fill out the form below to send me a message and I will get back to you as soon as possible!
            </p>
          </div>
          <div className="container mx-auto px-6 flex">
            <MyForm/>
          </div>
        </div>
      </Container>
      <Footer />
    </Layout>
  )
}

export default ContactPage
